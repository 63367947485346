.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

html,
body {
  height: 100%;
}

body,
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
}
.react-datepicker {
  width: 330px;
}
.disabled-link {
  pointer-events: none;
}
.ag-row-idle-color {
  background-color: #e5e5e5 !important;
}

.ag-row-done-color {
  background-color: #dae9b0 !important;
}

.ag-row-running-color {
  background-color: #d7f2f3 !important;
}

.ag-row-batch-color {
  background-color: #ffff2a !important;
}
.form-control {
  border:none;
}
.form-control:valid {
  background-color:  #e5e5e5!important;
}
.form-element input,select,textarea,.react-datepicker-wrapper{
  width:100%;
  box-sizing:border-box;
  border: lightgray 1px solid !important;
}
.form-element select {
  display: block;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: black;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  -webkit-appearance: menulist;
  box-sizing: border-box;
  align-items: center;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  background-color: white;
  cursor: default;
  margin: 0em;
  font: 400 13.3333px Arial;
  border-radius: 0px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(169, 169, 169);
  border-image: initial;
}
.card {
  width: 75%;
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
.error-message {
  color:red;
  font-size: 12px;
}
.list-group-item {
  user-select: none;
}

.list-group input[type="checkbox"] {
  display: none;
}

.list-group input[type="checkbox"] + .list-group-item {
  cursor: pointer;
}

.list-group input[type="checkbox"] + .list-group-item:before {
  content: "\2713";
  color: transparent;
  font-weight: bold;
  margin-right: 1em;
}

.list-group input[type="checkbox"]:checked + .list-group-item {
  background-color: #27a9e3;
  color: #FFF;
}

.list-group input[type="checkbox"]:checked + .list-group-item:before {
  color: inherit;
}

.list-group input[type="radio"] {
  display: none;
}

.list-group input[type="radio"] + .list-group-item {
  cursor: pointer;
}

.list-group input[type="radio"] + .list-group-item:before {
  content: "\2022";
  color: transparent;
  font-weight: bold;
  margin-right: 1em;
}

.list-group input[type="radio"]:checked + .list-group-item {
  background-color: #27a9e3;
  color: #FFF;
}

.list-group input[type="radio"]:checked + .list-group-item:before {
  color: inherit;
}

.modal-height {
  height: 90%;
}

/* .wrapper {
  background-color: grey;
  flex:1;
}

.header,
.footer {
  background-color: white;
  margin: 0;
  padding: 15px;
}

.content {
  background-color: #AA0000;
  flex: 1;
  display:flex;
  flex-direction: column;
} */
